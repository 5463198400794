export default {
  blogerName: 'VORD',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/VordGrupoVip',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@vord4179',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1gocasino.life/c3db46672',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/c1d89a03c',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c7f86a1c7',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c4904d825',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c9aac9566',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c230ec1db',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c4435c1e6',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c507fc732',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cfc8070b8',
      gameTitle: 'Jet Air (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>VORD</strong> e receba 100 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'VORD',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>100FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
